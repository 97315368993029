<template>
  <div>
    <img width="100%" height="100%" src="../icons/images/welcome.png" />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>